<template>
    <div class="publicDisclosure">
        <crumbs :item="title" />
        <div class="publicDisclosure1">
            <el-table :data="tableData" border  style="width: 100%" >
                <el-table-column
                    prop="fileTitle"
                    label="产品名称"
                    width="40%"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    label="产品报备材料"
                    width="20%"
                    align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="bottom-end">
                            <p v-for="(item, index) in scope.row.productFileList" :key="index" class="materialss"><a  :href="item.url" target="_blank">{{item.name}}</a></p>
                            <div slot="reference" class="name-wrapper" >
                                <el-tag size="medium">{{scope.row.productList}}</el-tag>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>            
                <el-table-column
                    prop="fileDate"
                    label="发布日期"
                    width="20%"
                    align="center"
                    v-if="false"
                    >
                </el-table-column>
                <el-table-column
                    prop="stopTime"
                    label="停止使用的起始日期"
                     width="20%"
                    align="center"
                    >
                </el-table-column>
            </el-table>
            <div class="paging">
                <p>共有{{ total }}条记录</p>
                <div>
                    <img
                    @click="getPageNumAReduction()"
                    src="~@/static/images/l_arrow@2x.png"
                    alt=""
                    />
                    <span>{{ pageNum }}/{{ pages }}</span>
                    <img
                    @click="getPageNumAdd()"
                    src="~@/static/images/ic_arrow_black@2x.png"
                    alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import crumbs from "@/components/crumbs.vue";
import { gethistorListPre } from "@/api/public";
Vue.use(Element)
const topTitle = [
  {
    AZ0070208: "产品基本信息",
    AZ008: "年度信息",
    AZ009: "重大事项",
    AZ010: "专项信息",
    AZ0100101: "逐笔披露",
    AZ0100102: "季度分类合并披露",
    AZ0100201: "资金运用类关联交易",
    AZ0100202: "风险责任人信息",
    AZ0100301: "投连险信息披露",
    AZ0100302: "万能险利率公告",
    AZ0100303: "分红险信息披露",
    AZ01004: "股东股权",
    AZ01005: "偿付能力",
    AZ01006: "互联网保险",
    AZ01007: "其他信息",
    AZ01008: "健康管理服务",
    AZ021: "投保提示书",
    AZ01010: "意外伤害保险",
    AZ01011: "社会责任报告",
    AZ01012: "短期健康保险",
  },
];
export default {
    name: "gethistorListPre",
    data() {
        return {
            title:[],
            idTitle: "",
            parentId:"",
            id:"",
            topTitle,
            value: "",
            dropdown:false,
            tableData:[],
            total: 0,
            pageNum: 0,
            pages: 0
        };
    }, 
    components: { crumbs },
    created() {
        this.id = this.$route.params.id;
        this.parentId = this.$route.params.parentId;
        this.request(0)
    },
    methods: {
        //下一页
        getPageNumAdd() {
            if (this.pageNum < this.pages) {
                this.pageNum = this.pageNum + 1;
                this.request(this.pageNum);
            } else {
                this.$message.warning("已经是最后一页了");
                console.log("no");
            }
        },
    // 上一页
        getPageNumAReduction() {
            if (this.pageNum <= 1) {
                this.$message.warning("已经是第一页了");
            } else {
                this.pageNum = this.pageNum - 1;
                this.request(this.pageNum);
            }
            },
        getStr2(string, str) {
            if(string&&str){
                let date=string.split(str)[0];
                return date;
            }else{
                return "--";
            }
        },
        openUrl(val){
            console.log("openUrl========",val.url)
            window.location.href=val.url
        },
        async request(num){
            this.tableData=[];
            try{
                this.topTitle.forEach((item) => (this.idTitle = item[this.parentId]));
                this.title=[
                    {
                        title: "公开信息披露",
                        link: "",
                    },
                    {
                        title: this.idTitle,
                        link: "",
                    },
                     {
                        title: "历史版本信息",
                        link: "",
                    }
                ]
                //请求后端接口
                const [timeStamp, sign] = this.$getSign();
                const res = await gethistorListPre({
                    timeStamp,
                    sign,
                    zid: this.$route.params.id,
                    pageNum: num
                });
                console.log("历史数据", res.data);
                if (res.data.code == 200) {
                    this.pageNum = res.data.pageNum;
                    this.pages = res.data.pages;
                    this.total = res.data.total;
                    res.data.rows.forEach(item=>{
                        let tableDataList={};
                        let fileUlrList=[];
                        let fileUlr={};
                        if(item.extraFileUrl){
                            fileUlr.name="产品说明书";
                            fileUlr.url=item.extraFileUrl;
                            fileUlrList.push(fileUlr);
                            fileUlr={};
                        }
                        if(item.fileUrl){
                            fileUlr.name="条款";
                            fileUlr.url=item.fileUrl;
                            fileUlrList.push(fileUlr);
                            fileUlr={};
                        }
                        if(item.rateScheduleUrl){
                            fileUlr.name="费率表";
                            fileUlr.url=item.rateScheduleUrl;
                            fileUlrList.push(fileUlr);
                            fileUlr={};
                        }
                        if(item.statementValueUrl){
                            fileUlr.name="现金价值";
                            fileUlr.url=item.statementValueUrl;
                            fileUlrList.push(fileUlr);
                            fileUlr={};
                        }
                        if(item.suspensionNoticeUrl){
                            fileUlr.name="停售公告";
                            fileUlr.url=item.suspensionNoticeUrl;
                            fileUlrList.push(fileUlr);
                            fileUlr={};
                        }
                        tableDataList.productList="请选择";
                        tableDataList.fileTitle=item.fileTitle;
                        tableDataList.stopTime=this.getStr2(item.stopTime,"T");
                        tableDataList.fileDate=this.getStr2(item.fileDate,"T");
                        tableDataList.createTime=this.getStr2(item.fileDate,"T");
                        tableDataList.productFileList=fileUlrList;
                        this.tableData.push(tableDataList);
                    })
                }
            }catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style lang="less" scoped>
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
  font-size: 14px !important;
}
.publicDisclosure {
    .publicDisclosure1{
        margin:0 10% 5% 10%;
    }   

}
::v-deep .el-table__body,::v-deep .el-table__header,::v-deep .el-table__empty-block{
    width: 100% !important;
}

</style>